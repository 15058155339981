import React from 'react'
import ReactPaginate from 'react-paginate';

export default function Pagination(props) {
    let {pagecount, handlePageClick, forcepage} = props;
  return (
    <div style={{padding: "10px 10px 10px 3%"}}>
        <ReactPaginate
        pageCount={pagecount}
        pageRangeDisplayed={4}
        marginPagesDisplayed={1}
        onPageChange={(event)=>handlePageClick(event)}
        containerClassName="pagination"
        activeClassName="active"
        pageLinkClassName="page-link"
        breakLinkClassName="page-link"
        nextLinkClassName="page-link"
        previousLinkClassName="page-link"
        pageClassName="page-item"
        breakClassName="page-item"
        nextClassName="page-item"
        previousClassName="page-item"
        previousLabel={<>&laquo;</>}
        nextLabel={<>&raquo;</>}
        forcePage={forcepage}
      />
    </div>
  )
}
